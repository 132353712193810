import { lazy , Suspense , useState } from 'react';
import { Routes , Route } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './App.css';

// Icons
import { BsLine } from 'react-icons/bs';

// Components
const Footer = lazy(() => import("Components/Footer/Footer/Footer"));
const NavbarBottom = lazy(() => import("Modules/NavbarBottom/NavbarBottom"));
const TopMainContent = lazy(() => import("Modules/TopMainContent/TopMainContent"));


// Pages
const HomePage = lazy(() => import("Pages/Home/Home"));
const LoginPage = lazy(() => import("Pages/Login/Login"));
const RegisterPage = lazy(() => import("Pages/Register/Register"));
const ContactPage = lazy(() => import("Pages/Contact/Contact"));
const PromotionPage = lazy(() => import("Pages/Promotion/Promotion"));
const ArticlePage01 = lazy(() => import("Pages/Article/Article01/Article01"));
const ArticlePage02 = lazy(() => import("Pages/Article/Article02/Article02"));
const ArticlePage03 = lazy(() => import("Pages/Article/Article03/Article03"));
const ArticlePage04 = lazy(() => import("Pages/Article/Article04/Article04"));
const ArticlePage05 = lazy(() => import("Pages/Article/Article05/Article05"));
const BlogsPage = lazy(() => import("Pages/Blogs/Blogs"));
const BlogPage = lazy(() => import("Pages/Blog/Blog"));

const NotFoundPage = lazy(() => import("Pages/Notfound/Notfound"));

const FrameLoginPage = lazy(() => import("Pages/Frame/LoginFrame/FrameLoginPage"));
const FrameRegister = lazy(() => import("Pages/Frame/RegisterFrame/FrameRegisterPage"));

function App() {
  const [ showPage , setShowPage ] = useState(false);
  return (
    // <>{ showPage ? 
    // <Suspense fallback="Loading...">
    //   <div className="App">
    //     <>
    //       <MessengerCustomerChat
    //         pageId="111660585159660"
    //         appId="750025749929835" 
    //       />
    //     </>
    //     <TopMainContent />
    //     <Routes>
    //         <Route path='/' element={<HomePage />} />
    //         <Route path='/login' element={<LoginPage />} />
    //         <Route path='/register' element={<RegisterPage />} />
    //         <Route path='/contact' element={<ContactPage />} />
    //         <Route path='/promotion' element={<PromotionPage />} />
    //         <Route path='/article/casino' element={<ArticlePage01 />} />
    //         <Route path='/article/betsoccer' element={<ArticlePage02 />} />
    //         <Route path='/article/technicsoccer' element={<ArticlePage03 />} />
    //         <Route path='/article/bacarat' element={<ArticlePage04 />} />
    //         <Route path='/article/promotionbacarat' element={<ArticlePage05 />} />
    //     </Routes>
    //   </div>
    //   <Footer />
    //   <NavbarBottom />
    // </Suspense>
    // : 
    // <Suspense fallback="Loading...">
    //   <Routes>
    //     <Route path='/' element={<NotFoundPage setShowPage={setShowPage} />} />
    //   </Routes>
    // </Suspense>
    // } </>

    <Suspense fallback="Loading...">
      <div className="App">
        <a href='https://line.me/ti/p/@messinew1' className='line-stick-mobile' ><BsLine /></a>
        <>
          <MessengerCustomerChat
            pageId="111660585159660"
            appId="750025749929835" 
          />
        </>
        {/* <TopMainContent /> */}
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/login' element={<LoginPage />} />
            {/* <Route path='/register' element={<RegisterPage />} /> */}
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/promotion' element={<PromotionPage />} />
            <Route path='/article/casino' element={<ArticlePage01 />} />
            <Route path='/article/betsoccer' element={<ArticlePage02 />} />
            <Route path='/article/technicsoccer' element={<ArticlePage03 />} />
            <Route path='/article/bacarat' element={<ArticlePage04 />} />
            <Route path='/article/promotionbacarat' element={<ArticlePage05 />} />
            <Route path='/blogs' element={<BlogsPage />} />
            <Route path='/blog/:id' element={<BlogPage />} />

            <Route path='/play' element={<FrameLoginPage />} />
            <Route path='/register' element={<FrameRegister />} />
        </Routes>
      </div>
      {/* <Footer />
      <NavbarBottom /> */}
    </Suspense>
  );
}

export default App;
